<template>
	<el-container style="min-height: 100vh">
		<el-aside width="200px">
			<div style="width: 100%; height: 60px; background-color: #4190e2; display: flex; justify-content: space-around; align-items: center; color: #fff; font-size: 20px">和上金融</div>
			<el-menu :default-active="this.$route.meta.asideMenu" class="el-menu-vertical-demo" :router="true" :default-openeds="openeds">
				<el-menu-item :route="{ path: '/admin/home_page/index' }" index="AdminHomePageIndex">首页</el-menu-item>
				<el-menu-item-group>
					<el-menu-item :route="{ path: '/admin/admin_manage/index' }" index="adminManage">账号管理</el-menu-item>
					<el-menu-item :route="{ path: '/admin/merchant_verify/index' }" index="merchantVerify">商户审核</el-menu-item>
					<el-menu-item :route="{ path: '/admin/merchant_manage/index' }" index="merchantManage">商户管理</el-menu-item>
					<el-menu-item :route="{ path: '/admin/order_manage/index' }" index="orderManage">交易管理</el-menu-item>
				</el-menu-item-group>
			</el-menu>
		</el-aside>
		<el-container>
			<el-header>
				<div style="float: right; margin-right: 20px">
					<el-dropdown>
						<a
							><span>{{ account }}-{{ role_name }}</span></a
						>
						<el-dropdown-menu slot="dropdown">
							<router-link to="/change_password">
								<el-dropdown-item>
									<span>修改密码</span>
								</el-dropdown-item>
							</router-link>
							<el-dropdown-item>
								<div @click="logout">
									<span>登出</span>
								</div>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>

					<el-dropdown>
						<span class="el-dropdown-link">
							<img :src="off" alt="" style="width: 20px; height: 20px; border-radius: 15px; margin-left: 20px; cursor: pointer" />
						</span>
						<el-dropdown-menu slot="dropdown">
							<router-link to="/change_password">
								<el-dropdown-item>
									<span>修改密码</span>
								</el-dropdown-item>
							</router-link>
							<el-dropdown-item>
								<div @click="logout">
									<span>登出</span>
								</div>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</el-header>
			<el-main>
				<transition name="fade-transform" mode="out-in">
					<router-view :key="key" />
				</transition>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	import off from '@/assets/image/admin/off.png'

	export default {
		name: 'admin_layout',
		data: function () {
			return {
				account: null,
				role_name: null,
				off,
				openeds: ['AdminBasicInfo']
			}
		},
		created() {
			this.account = localStorage.getItem('admin_admin_name')
			this.role_name = localStorage.getItem('admin_role_name')
		},
		methods: {
			logout() {
				let _this = this
				_this
					.$confirm('是否登出?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.$store.commit('logoutAdmin')
						this.$router.push({ path: '/admin/login' })
					})
					.catch(() => {})
			}
		},
		computed: {
			key() {
				return this.$route.fullPath
			}
		}
	}
</script>

<style>
	html,
	body {
		height: 100%;
		max-width: unset;
	}

	.el-header {
		/*background-color: #B3C0D1;*/
		color: #333;
		text-align: left;
		line-height: 60px;
		background-color: #fff;
	}

	.el-aside {
		background-color: #fff;
		color: #333;
		/*text-align: center;*/
		line-height: 200px;
	}

	.el-main {
		background-color: #e9eef3;
		color: #333;
		/*text-align: center;*/
		/*line-height: 160px;*/
	}

	body > .el-container {
		margin-bottom: 40px;
	}

	.el-container {
		min-height: 100%;
	}

	.el-container:nth-child(5) .el-aside,
	.el-container:nth-child(6) .el-aside {
		line-height: 260px;
	}

	.el-container:nth-child(7) .el-aside {
		line-height: 320px;
	}

	.el-submenu__title {
		text-align: left !important;
	}

	.el-form-item__content > .el-select {
		width: 100% !important;
	}
</style>
<style scoped lang="scss">
	.admin-aside-menu-icon {
		width: 24px;
		height: 18px;
		display: inline-block;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}

	.router-link-active {
		color: #409eff;
	}
</style>
